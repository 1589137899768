import { VideoDataType } from "../types";

export default function convertToVideoDataWithVimeoUrl(
  videoData: VideoDataType[],
) {
  return videoData.map((video) => {
    const { videoUrl, introVideoUrl } = video;

    if (videoUrl)
      video.videoUrl = `https://player.vimeo.com/video/${videoUrl}?title=0&byline=0&portrait=0`;

    if (introVideoUrl)
      video.introVideoUrl = `https://player.vimeo.com/video/${introVideoUrl}?title=0&byline=0&portrait=0`;

    return video;
  });
}
