export enum SourceType {
  Web = "web",
  ClubApp = "clubApp",
  DemoApp = "demoApp",
}

export type MenuItemType =
  | "event"
  | "listen"
  | "watch"
  | "playing"
  | "story"
  | "bedtime";

export type GameType =
  | "magicPenHome"
  | "magicPen"
  | "talkAlongCards"
  | "QACards";
export type MagicPenType = "mickey" | "minnie";
export type TACardType = "picnic" | "play" | "eat" | "cars";
export type QACardType = "qa34" | "qa39" | "qa32";

export type MarketType = "HK" | "KR" | "TW";
export type LanguageType = "zh-HK" | "ko" | "zh-TW";

export type SegmentationType = "Awareness" | "Interest" | "Decision" | "Action";

export interface UserInformation {
  deviceId: string;
  trialUserId: number;
  userName: string;
  phoneNumber: string;
  trialUserSegmentation: SegmentationType;
  market: MarketType;
  roles: string[];
  leadsLinkAddress: string;
  bookDemoLinkAddress: string;
  expiredDate: string | null;
  isVerified: boolean;
}
