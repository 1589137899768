import { useTranslation } from "react-i18next";

import bubbleWeb from "../../assets/images/menu/disneyWeb/bubble-xl.svg";
import Text from "../../components/ui/Text";
import Touchable from "../../components/ui/Touchable";
import useStore from "../../hooks/store/useStore";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import cn from "../../utils/cn";
import {
  menuItemData,
  positionForNonBedtime,
  positionForBedtime,
} from "./constants";

export default function ClubAppMenuItem() {
  const { t } = useTranslation();

  const { setShowAppDownloadModel } = useStore((state) => state.webVersion);
  const { userInformation } = useStore((state) => state.user);
  const { market } = userInformation || {};

  const mutation = useEventTracking();

  const handleMenuClick = () => {
    mutation.mutate({
      pageId: "Disney Menu",
      eventId: "WorldFamilyClub",
    });

    setShowAppDownloadModel(true);
  };

  const { image, delay } = menuItemData["clubApp"];
  const positionMapping =
    market === "HK" ? positionForBedtime : positionForNonBedtime;

  return (
    <div
      className={cn(
        "disney-menu-slide-in absolute flex size-40 flex-col items-center justify-center bg-cover pt-2",
        positionMapping.clubApp,
      )}
      style={{ backgroundImage: `url(${bubbleWeb})`, animationDelay: delay }}
      onClick={handleMenuClick}
    >
      <Touchable>
        <div className="flex flex-col items-center">
          <img src={image} alt="app icon" className="size-[68px]" />
        </div>

        <Text lg className="w-28 text-center font-medium">
          {t("worldFamilyClub")}
        </Text>
      </Touchable>
    </div>
  );
}
