import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import background from "../../assets/images//menu/disney/background.png";
import bubble from "../../assets/images/shared/bubble.png";
import bubble2 from "../../assets/images/shared/bubble2.png";
import Text from "../../components/ui/Text";
import useStore from "../../hooks/store/useStore";
import { MenuItemType } from "../../types";
import cn from "../../utils/cn";
import BookButton from "../bookDemo/BookButton";
import MenuItem from "./MenuItem";
import { positionForBedtime, positionForNonBedtime } from "./constants";

export default function DisneyMenu({
  setSelectedMenuItem,
  setShowDisneyMenu,
}: {
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemType>>;
  setShowDisneyMenu: Dispatch<SetStateAction<boolean | null>>;
}) {
  const { t } = useTranslation();
  const { userInformation } = useStore((state) => state.user);
  const { market } = userInformation || {};

  const positionMapping =
    market === "HK" ? positionForBedtime : positionForNonBedtime;

  return (
    <div className="relative flex min-h-screen w-full justify-center bg-pink1">
      <div className="flex min-h-screen w-screen min-w-[320px] max-w-[480px] flex-col bg-pink1">
        <div className="flex h-11 items-center justify-center">
          <Text lg>{t("digitalExperience")}</Text>
        </div>

        <div className="mx-10 mb-6 mt-4">
          <Text className="text-center">{t("disneyDescription")}</Text>
        </div>

        <div
          className="relative flex-1 bg-cover bg-top pt-12"
          style={{ backgroundImage: `url(${background})` }}
        >
          <MenuItem
            name="donald"
            className={cn(positionMapping.donald)}
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
          />
          <MenuItem
            name="minnie"
            className={cn(positionMapping.minnie)}
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
          />
          <MenuItem
            name="mickey"
            className={cn(positionMapping.mickey)}
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
          />
          <MenuItem
            name="pluto"
            className={cn(positionMapping.pluto)}
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
          />
          <MenuItem
            name="goofy"
            className={cn(positionMapping.goofy)}
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
          />
          {market === "HK" ? (
            <MenuItem
              name="daisy"
              className={cn(positionMapping.daisy)}
              setSelectedMenuItem={setSelectedMenuItem}
              setShowDisneyMenu={setShowDisneyMenu}
            />
          ) : null}

          <img
            src={bubble}
            alt="bubble"
            className={cn("floating absolute size-10", positionMapping.bubble1)}
          />
          <img
            src={bubble2}
            alt="bubble2"
            className={cn("floating absolute size-6", positionMapping.bubble2)}
            style={{ animationDelay: "0.7s" }}
          />
          <img
            src={bubble}
            alt="bubble"
            className={cn("floating absolute size-6", positionMapping.bubble3)}
            style={{ animationDelay: "1.3s" }}
          />
        </div>

        <BookButton sourcePage="Disney Menu" />
      </div>
    </div>
  );
}
