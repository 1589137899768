import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import bubble from "../../assets/images/menu/disney/bubble-xl.webp";
import bubbleWeb from "../../assets/images/menu/disneyWeb/bubble-xl.svg";
import Text from "../../components/ui/Text";
import Touchable from "../../components/ui/Touchable";
import useStore from "../../hooks/store/useStore";
import { MenuTypeMapping } from "../../services/EventTracking/mapping";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import cn from "../../utils/cn";
import { MenuItemType } from "../../types";
import {
  menuItemData,
  stylesForBedtime,
  stylesForNonBedtime,
} from "./constants";

export default function MenuItem({
  name,
  className,
  setSelectedMenuItem,
  setShowDisneyMenu,
  isPink,
}: {
  name: string;
  className?: string;
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemType>>;
  setShowDisneyMenu: Dispatch<SetStateAction<boolean | null>>;
  isPink?: boolean;
}) {
  const { t } = useTranslation();
  const { userInformation } = useStore((state) => state.user);
  const { market } = userInformation || {};

  const mutation = useEventTracking();

  const { type, image, icon, delay } = menuItemData[name];
  const bubbleImage = isPink ? bubbleWeb : bubble;
  const { bubbleWidth, charactorWidth, fontSize } =
    market === "HK" ? stylesForBedtime : stylesForNonBedtime;

  if (type === null) throw new Error("Type should not be null in MenuItem");

  const handleMenuClick = () => {
    mutation.mutate({
      pageId: "Disney Menu",
      eventId: MenuTypeMapping[type],
    });

    setSelectedMenuItem(type);
    setShowDisneyMenu(false);
  };

  return (
    <div
      className={cn(
        "disney-menu-slide-in flex flex-col items-center bg-cover pt-2",
        bubbleWidth,
        className,
      )}
      style={{
        backgroundImage: `url(${bubbleImage})`,
        animationDelay: delay,
      }}
      onClick={handleMenuClick}
    >
      <Touchable>
        <img
          src={image}
          alt="disney character"
          className={cn(charactorWidth)}
        />

        <div className="flex items-center justify-center gap-1">
          <Text className={cn(fontSize, "font-medium")}>{t(type)}</Text>

          {icon ? <img src={icon} alt="icon" className="size-5" /> : null}
        </div>
      </Touchable>
    </div>
  );
}
