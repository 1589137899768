import { ReactElement, cloneElement } from "react";
import { useTranslation } from "react-i18next";

import BookDemoLink from "../../components/bookdemo/BookDemoLink";
import useStore from "../../hooks/store/useStore";
import { EventTrackingSourcePageType } from "../../services/EventTracking/types";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import { SourceType } from "../../types";

import RoundedButton from "./components/RoundedButton";
import BookButtonWeb from "./BookButtonWeb";
import BookDemoDrawer from "./BookDemoDrawer";

export default function BookButton({
  sourcePage,
  buttonNode,
  displayBookButton = true,
}: {
  sourcePage: EventTrackingSourcePageType;
  buttonNode?: ReactElement;
  displayBookButton?: boolean;
}) {
  const { t } = useTranslation();

  const { source } = useStore((state) => state.global);
  const { userInformation } = useStore((state) => state.user);
  const { setShowBookDemoDrawer } = useStore((state) => state.bookDemo);

  const mutation = useEventTracking();

  const { trialUserSegmentation: seg } = userInformation || {};

  if (source === SourceType.ClubApp && (seg === "Decision" || seg === "Action"))
    return null;

  const texts = t("bookDemo").split(" ");
  const rightButtonText = t("bookDemo").replace(" ", "");

  const handleEventTracking = () => {
    mutation.mutate({
      pageId: "Booked Demo",
      eventId: sourcePage,
    });
  };

  const handleBookClick = () => {
    handleEventTracking();
    setShowBookDemoDrawer(true);
  };

  if (seg === "Awareness") {
    return displayBookButton ? (
      <BookDemoLink sourcePage={sourcePage}>
        <RoundedButton texts={texts} />
      </BookDemoLink>
    ) : null;
  }

  const ButtonComponent = buttonNode ? (
    cloneElement(buttonNode, {
      onClick: (e: React.MouseEvent) => {
        buttonNode.props.onClick?.(e);

        handleBookClick();
      },
    })
  ) : (
    <RoundedButton texts={texts} onClick={handleBookClick} />
  );

  return displayBookButton ? (
    <>
      {source === SourceType.Web ? (
        <BookButtonWeb
          leftButtonText="Download Club app"
          rightButtonText={rightButtonText}
          onClick={handleBookClick}
        />
      ) : (
        ButtonComponent
      )}

      <BookDemoDrawer />
    </>
  ) : (
    <BookDemoDrawer />
  );
}
