import eventIcon from "../../assets/images/menu/event.svg";
import listenIcon from "../../assets/images/menu/listen-active.svg";
import watchIcon from "../../assets/images/menu/watch-active.svg";
import playingIcon from "../../assets/images/menu/playing-active.svg";
import storyIcon from "../../assets/images/menu/storyIcon.svg";
import { MenuItemType } from "../../types";

interface MenuItemDataType {
  type: MenuItemType;
  icon: string;
}

export const eventMenuItem: MenuItemDataType = {
  type: "event",
  icon: eventIcon,
};

export const actionUserMenuItemData: MenuItemDataType[] = [
  {
    type: "listen",
    icon: listenIcon,
  },
  {
    type: "watch",
    icon: watchIcon,
  },
  {
    type: "playing",
    icon: playingIcon,
  },
  {
    type: "story",
    icon: storyIcon,
  },
];

export const bedtimeMenuItem: MenuItemDataType = {
  type: "bedtime",
  icon: listenIcon,
};
