import { Dispatch, SetStateAction } from "react";

import dweLogo from "../../assets/images/webLogin/dweLogo.svg";
import bubble1 from "../../assets/images/menu/disneyWeb/bubble1.svg";
import bubble2 from "../../assets/images/menu/disneyWeb/bubble2.png";
import bubble3 from "../../assets/images/menu/disneyWeb/bubble3.svg";
import useStore from "../../hooks/store/useStore";
import { MenuItemType } from "../../types";
import cn from "../../utils/cn";
import BookButton from "../bookDemo/BookButton";
import AppDownloadModel from "../listen/components/AppDownloadModel";
import MenuItem from "./MenuItem";
import ClubAppMenuItem from "./ClubAppMenuItem";
import { positionForNonBedtime, positionForBedtime } from "./constants";

export default function DisneyMenuWeb({
  setSelectedMenuItem,
  setShowDisneyMenu,
}: {
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemType>>;
  setShowDisneyMenu: Dispatch<SetStateAction<boolean | null>>;
}) {
  const { showAppDownloadModel } = useStore((state) => state.webVersion);
  const { userInformation } = useStore((state) => state.user);
  const { market } = userInformation || {};

  const positionMapping =
    market === "HK" ? positionForBedtime : positionForNonBedtime;

  return (
    <div className="relative flex min-h-screen w-full flex-col items-center justify-start bg-gradient-to-b from-white to-pink1">
      <img src={dweLogo} alt="dweLogo" className="mb-9 mt-4 w-[162px]" />

      <div className="relative flex w-screen min-w-[320px] max-w-[480px] flex-col">
        <MenuItem
          name="donald"
          className={cn(positionMapping.donald)}
          setSelectedMenuItem={setSelectedMenuItem}
          setShowDisneyMenu={setShowDisneyMenu}
          isPink
        />
        <MenuItem
          name="minnie"
          className={cn(positionMapping.minnie)}
          setSelectedMenuItem={setSelectedMenuItem}
          setShowDisneyMenu={setShowDisneyMenu}
          isPink
        />
        <MenuItem
          name="mickey"
          className={cn(positionMapping.mickey)}
          setSelectedMenuItem={setSelectedMenuItem}
          setShowDisneyMenu={setShowDisneyMenu}
          isPink
        />
        <MenuItem
          name="pluto"
          className={cn(positionMapping.pluto)}
          setSelectedMenuItem={setSelectedMenuItem}
          setShowDisneyMenu={setShowDisneyMenu}
          isPink
        />
        <MenuItem
          name="goofy"
          className={cn(positionMapping.goofy)}
          setSelectedMenuItem={setSelectedMenuItem}
          setShowDisneyMenu={setShowDisneyMenu}
          isPink
        />
        <ClubAppMenuItem />

        <img
          src={bubble1}
          alt="bubble1"
          className={cn("floating absolute size-10", positionMapping.bubble1)}
        />
        <img
          src={bubble2}
          alt="bubble2"
          className={cn("floating absolute size-6", positionMapping.bubble2)}
          style={{ animationDelay: "0.7s" }}
        />
        <img
          src={bubble3}
          alt="bubble3"
          className={cn("floating absolute size-6", positionMapping.bubble3)}
          style={{ animationDelay: "1.3s" }}
        />
      </div>

      {showAppDownloadModel ? (
        <AppDownloadModel eventId="World Family Club" />
      ) : null}

      <BookButton sourcePage="Disney Menu" />
    </div>
  );
}
