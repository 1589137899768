import { RefObject } from "react";

import { MarketType, MenuItemType, SegmentationType } from "../../../types";

function getScrollLeft(
  menuRef: RefObject<HTMLUListElement>,
  type: MenuItemType,
  types: MenuItemType[],
) {
  const container = menuRef.current;

  if (!container || !container.parentElement) return null;

  const index = types.indexOf(type);
  const containerWidth = container.parentElement.offsetWidth;
  const currentItem = container.children[index] as HTMLElement;
  const leftInScreen = (containerWidth - currentItem.offsetWidth) / 2;
  let left = 0;

  for (let i = 0; i < index; i++) {
    const item = container.children[i] as HTMLElement;
    left += item.offsetWidth;
    // gap
    left += 4;
  }

  return left - leftInScreen;
}

export default function scrollMenuItem(
  menuRef: RefObject<HTMLUListElement>,
  type: MenuItemType,
  trialUserSegmentation: SegmentationType,
  market: MarketType | undefined,
) {
  const types: MenuItemType[] = ["listen", "watch", "playing", "story"];

  if (market === "HK") types.splice(1, 0, "bedtime");
  if (trialUserSegmentation !== "Action") types.unshift("event");

  const left = getScrollLeft(menuRef, type, types);

  if (menuRef.current?.parentElement && left)
    menuRef.current.parentElement.scrollTo({
      left,
      behavior: "smooth",
    });
}
