import { Dispatch, SetStateAction } from "react";

import lyricsIcon from "../../../assets/images/listen/lyrics.svg";
import Text from "../../../components/ui/Text";
import Tag from "../components/Tag";

export default function SongTitle({
  songTitle,
  lyricsUrl,
  showLyrics,
  setShowLyrics,
}: {
  songTitle: string;
  lyricsUrl: string | null;
  showLyrics: boolean;
  setShowLyrics: Dispatch<SetStateAction<boolean>>;
}) {
  return !showLyrics ? (
    <div className="flex items-center justify-between overflow-hidden">
      <Text lg>{songTitle}</Text>

      {lyricsUrl ? (
        <Tag
          text="lyrics"
          className="bg-pink0"
          icon={lyricsIcon}
          onClick={() => setShowLyrics(true)}
        />
      ) : null}
    </div>
  ) : null;
}
