import { Dispatch, SetStateAction, useRef } from "react";

import withDelay from "../utils/withDelay";
import { SongDataType } from "../types";
import Display from "./Display";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";
import SongTitle from "./SongTitle";

export default function AudioPlayer({
  songs,
  showLyrics,
  setShowLyrics,
  currentSong,
  setCurrentSong,
  songIndex,
  setSongIndex,
  timeProgress,
  setTimeProgress,
  isPlaying,
  setIsPlaying,
  repeat,
  setRepeat,
  setCurrentLyricIndex,
  unknown,
  setUnknown,
}: {
  songs: SongDataType[];
  showLyrics: boolean;
  setShowLyrics: Dispatch<SetStateAction<boolean>>;
  currentSong: SongDataType;
  setCurrentSong: Dispatch<SetStateAction<SongDataType | null>>;
  songIndex: number;
  setSongIndex: Dispatch<SetStateAction<number>>;
  timeProgress: number;
  setTimeProgress: Dispatch<SetStateAction<number>>;
  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  repeat: boolean;
  setRepeat: Dispatch<SetStateAction<boolean>>;
  setCurrentLyricIndex: Dispatch<SetStateAction<number>>;
  unknown: boolean;
  setUnknown: Dispatch<SetStateAction<boolean>>;
}) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressBarRef = useRef<HTMLInputElement>(null);

  const handleNext = withDelay(
    () => {
      if (songIndex >= songs.length - 1) {
        setSongIndex(0);
        setCurrentSong(songs[0]);
      } else {
        setSongIndex((prev) => prev + 1);
        setCurrentSong(songs[songIndex + 1]);
      }

      setTimeout(() => setCurrentLyricIndex(-1), 100);
    },
    unknown,
    setUnknown,
  );

  const handleEnd = () => {
    setIsPlaying(false);
    setTimeProgress(0);
    setCurrentLyricIndex(-1);

    if (audioRef.current) audioRef.current.currentTime = 0;
  };

  return (
    <div className="flex flex-col gap-4 px-8 pb-[17px] pt-4">
      <Display
        audioRef={audioRef}
        progressBarRef={progressBarRef}
        currentSong={currentSong}
        showLyrics={showLyrics}
        repeat={repeat}
        handleNext={handleNext}
        handleEnd={handleEnd}
      />
      <Controls
        audioRef={audioRef}
        progressBarRef={progressBarRef}
        setTimeProgress={setTimeProgress}
        songs={songs}
        songIndex={songIndex}
        setSongIndex={setSongIndex}
        setCurrentSong={setCurrentSong}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        handleNext={handleNext}
        repeat={repeat}
        setRepeat={setRepeat}
        unknown={unknown}
        setUnknown={setUnknown}
      />
      <ProgressBar
        audioRef={audioRef}
        progressBarRef={progressBarRef}
        timeProgress={timeProgress}
      />
      <SongTitle
        songTitle={currentSong.songTitle}
        lyricsUrl={currentSong.audio.lyricsUrl}
        showLyrics={showLyrics}
        setShowLyrics={setShowLyrics}
      />
    </div>
  );
}
