import mickey from "../../assets/images/menu/disney/mickey.png";
import minnie from "../../assets/images/menu/disney/minnie.png";
import donald from "../../assets/images/menu/disney/donald.png";
import pluto from "../../assets/images/menu/disney/pluto.png";
import goofy from "../../assets/images/menu/disney/goofy.png";
import daisy from "../../assets/images/menu/disney/daisy.png";
import appIcon from "../../assets/images/menu/disneyWeb/clubApp.png";
import eventIcon from "../../assets/images/menu/event.svg";
import listenIcon from "../../assets/images/menu/listen-active.svg";
import watchIcon from "../../assets/images/menu/watch-active.svg";
import playingIcon from "../../assets/images/menu/playing-active.svg";
import storyIcon from "../../assets/images/menu/storyIcon.svg";
import { MenuItemType } from "../../types";

export const menuItemData: {
  [key: string]: {
    type: MenuItemType | null;
    image: string;
    icon: string | null;
    delay: string;
  };
} = {
  donald: {
    type: "event",
    image: donald,
    icon: eventIcon,
    delay: "0s",
  },
  minnie: {
    type: "listen",
    image: minnie,
    icon: listenIcon,
    delay: "0.3s",
  },
  mickey: {
    type: "watch",
    image: mickey,
    icon: watchIcon,
    delay: "0.6s",
  },
  pluto: {
    type: "playing",
    image: pluto,
    icon: playingIcon,
    delay: "0.9s",
  },
  goofy: {
    type: "story",
    image: goofy,
    icon: storyIcon,
    delay: "1.2s",
  },
  daisy: {
    type: "bedtime",
    image: daisy,
    icon: listenIcon,
    delay: "1.5s",
  },
  clubApp: {
    type: null,
    image: appIcon,
    icon: null,
    delay: "1.8s",
  },
};

export const positionForNonBedtime: {
  [key: string]: string;
} = {
  donald: "absolute top-[36px] left-[56px]",
  minnie: "absolute top-[123px] right-[8px]",
  mickey: "absolute top-[203px] left-[78px]",
  pluto: "absolute top-[306px] right-[-7px]",
  goofy: "absolute top-[363px] left-[11px]",
  clubApp: "absolute top-[466px] left-[156px]",
  daisy: "",
  bubble1: "top-[77px] right-[25px]",
  bubble2: "top-[160px] left-[23px]",
  bubble3: "top-[470px] right-[28px]",
};

export const positionForBedtime: {
  [key: string]: string;
} = {
  donald: "absolute top-[25px] left-[150px]",
  minnie: "absolute top-[153px] right-[10px]",
  mickey: "absolute top-[290px] left-[170px]",
  pluto: "absolute top-[445px] right-[56px]",
  goofy: "absolute top-[322px] left-[15px]",
  clubApp: "",
  daisy: "absolute top-[139px] left-[28px]",
  bubble1: "top-[77px] right-[25px]",
  bubble2: "top-[289px] left-[14px]",
  bubble3: "top-[533px] right-[13px]",
};

export const stylesForNonBedtime: {
  [key: string]: string;
} = {
  bubbleWidth: "size-[152px]",
  charactorWidth: "size-24",
  fontSize: "text-[17px]",
};

export const stylesForBedtime: {
  [key: string]: string;
} = {
  bubbleWidth: "size-[140px]",
  charactorWidth: "size-[88px]",
  fontSize: "text-[15px]",
};
