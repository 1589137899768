import { GameType, MagicPenType, MenuItemType } from "../../types";
import {
  EventTrackingMagicPenType,
  EventTrackingMenuType,
  EventTrackingSourcePageType,
} from "./types";

export const MenuTypeMapping: {
  [key in MenuItemType]: EventTrackingMenuType;
} = {
  event: "Event",
  listen: "Listen",
  watch: "Watch",
  playing: "Playing",
  story: "Story",
  bedtime: "Bedtime Songs",
};

export const MagicPenMapping: {
  [key in MagicPenType]: EventTrackingMagicPenType;
} = {
  mickey: "Mickey",
  minnie: "Minnie",
};

export const GameSourcePageMapping: {
  [key in GameType]: EventTrackingSourcePageType;
} = {
  magicPenHome: "Magic Pen Home",
  magicPen: "Magic Pen",
  talkAlongCards: "Talk Along Cards",
  QACards: "Q & A Cards",
};
